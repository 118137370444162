import React, {FC, useEffect, useState} from 'react';
import styles from './app.module.scss';
import Heart from "../../component/heart/heart";
import request from "../../utils/request";
import {HeartFill} from "antd-mobile-icons";
import {Toast} from "antd-mobile";
import {SketchPicker} from 'react-color';
import {rgbList2hex} from "../../utils/color";


const App: FC = () => {
    const [
        rgb, setRGB
    ] = useState<number[]>([0, 0, 0])

    const [
        color, setColor
    ] = useState<string>('')

    const [
        colorSelectorVisible, setColorSelectorVisible
    ] = useState(false)

    const [onMissing, setOnMissing] = useState(false);


    useEffect(() => {
        // 组件挂载时设置定时器
        const timer = setInterval(() => {
            updateState();
        }, 800);
        // 组件卸载时清除定时器
        return () => clearInterval(timer);
    }, []);

    const updateState = async () => {
        const data: {
            data: [number],
            notification: boolean
        } = await request.get('/get_device_state')
        setRGB(data.data)

        if (rgbList2hex(data.data).toLowerCase() === color.toLowerCase()) {
            setColor('')
        }

        if (data.notification) {
            //     发送通知 + 关闭显示
            setOnMissing(false)
            Toast.show({
                icon: <HeartFill/>,
                content: `I'm missing you!`,
            })
        }
    }

    return (
        <div className={styles.app}>

            <div className={styles['app-header']}>
                Woodman123 Light
            </div>

            <div className={styles['app-body']}>
                <div className={styles['app-body-content']}>
                    <Heart
                        onMissing={onMissing}
                        setOnMissing={setOnMissing}
                    />
                </div>
            </div>

            <div className={styles['app-body-color']}>
                <div className={styles['app-body-color-button']} style={{
                    backgroundColor: ` ${color !== '' ? color : rgbList2hex(rgb)}`
                }} onClick={() => {
                    setColorSelectorVisible(true)
                }}
                />
                <div
                    className={styles['color-selector']}
                    style={{
                        display: colorSelectorVisible ? 'block' : 'none'
                    }}
                >
                    <SketchPicker
                        color={rgbList2hex(rgb)}
                        onChangeComplete={async (c) => {
                            setColor(c.hex)
                            setColorSelectorVisible(false)

                            await request.get(
                                '/set_device_state',
                                {
                                    params: {
                                        notification: 0,
                                        r: c.rgb.r,
                                        g: c.rgb.g,
                                        b: c.rgb.b
                                    }
                                }
                            )

                        }}
                    />
                </div>
            </div>


            <div className={styles['app-footer']}>
                <span>© 2023 - 2024</span>
                <HeartFill style={
                    {
                        margin: '0 5px',
                    }
                }/>
                <a href="https://github.com/Littleor">Littleor</a>
                <span style={
                    {
                        margin: '0 3px',
                    }
                }>-</span>
                <a href="https://github.com/NexMaker-Fab/2023zjude-Woodman123">Woodman123</a>
            </div>
        </div>
    );
}

export default App;
