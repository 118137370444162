// 1.创建一个 axios 实例
import axios from "axios";

const request = axios.create({
    baseURL: 'https://light-server.littleor.cn',
    timeout: 5000,
});

// 2.请求拦截器
request.interceptors.request.use(
    (config) => {
        // 2.1.请求发出成功的时候需要做什么
        // 2.2.带上 token
        // 2.3.数据转换，请求发送出去的数据进行转换
        return config;
    },
    (err) => {
        // 2.4.请求发出失败的时候需要做什么
        return Promise.reject(err);
    }
);

request.interceptors.response.use(
    (res) => {
        // 3.1.响应成功的时候做什么
        // 3.2.数据转换，响应数据进行转换
        return res.data;
    },
    (err) => {
        // 3.3.响应失败的时候做什么
        return Promise.reject(err);
    }
);

export default request;
