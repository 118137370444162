export const rgb2hex = (r: number, g: number, b: number) => {
    let hex = ((r << 16) | (g << 8) | b).toString(16);
    return '#' + new Array(Math.abs(hex.length - 7)).join('0') + hex;
}

export const rgbList2hex = (data: number[]) => {
    return rgb2hex(
        data[0],
        data[1],
        data[2]
    );
}

export const hex2rgb = (hex: string) => {
    hex = hex.charAt(0) === '#' ? hex.substr(1) : hex;
    let r = parseInt(hex.substr(0, 2), 16);
    let g = parseInt(hex.substr(2, 2), 16);
    let b = parseInt(hex.substr(4, 2), 16);
    return [r, g, b];
}
