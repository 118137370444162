import React, {FC, useState} from 'react';
import styles from './heart.module.scss';
import {HeartFill} from "antd-mobile-icons";
import request from "../../utils/request";

const defaultMissingConfig = {
    speed: 500,
    count: -1,
    r: 250,
    g: 92,
    b: 126
}

const Heart = ({
                   onMissing,
                   setOnMissing
               }: {
    onMissing: boolean,
    setOnMissing: (onMissing: boolean) => void
}) => {


    const sendMissing = async () => {
        const result = await request.get(
            '/notification_device',
            {
                params: defaultMissingConfig
            }
        )
        console.log(result)
    }

    const cancelMissing = async () => {
        const result = await request.get(
            '/notification_device',
            {
                params: {
                    ...defaultMissingConfig,
                    count: -2
                }
            }
        )
        console.log(result)
    }

    return (
        <div className={styles['button']} onClick={async () => {
            if (!onMissing) {
                sendMissing()
            } else {
                cancelMissing()
            }

            setOnMissing(!onMissing)

        }}>
            {
                onMissing ?
                    <HeartFill fontSize={128}
                               className={`${styles['button-icon']} ${styles['button-icon-twinkle']}`}
                    /> : <HeartFill
                        className={styles['button-icon']}
                        fontSize={128}/>
            }
        </div>
    );
}

export default Heart;
